<template>
    <v-snackbar
      v-model="shouldShowMessage"
      :timeout="-1"
    >
      {{ getTextFromHTML(data.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="data.button"
          color="primary"
          v-bind="attrs"
          @click="callAction"
          text
        >
          {{ data.button.text }}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "ToastMessageItem",

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    timeoutFunction: null,
    shouldShowMessage: true,
  }),

  // Define local method functions
  methods: {
    /**
     * Delete the toast message from the stack
     *
     * @returns {void}
     */
    destroySelf() {
      // Unset timeout function as well
      if (this.timeoutFunction !== null) {
        clearTimeout(this.timeoutFunction)
      }

      // Set the timeout function to null
      this.timeoutFunction = null

      // Remove the toast with key
      this.$store.dispatch("toasts/remove", this.data.key)
    },

    /**
     * If an action is defined for this toast message, ignite it
     *
     * @returns {void}
     */
    callAction() {
      // call the callable function
      if (typeof this.data.button.action === "function") {
        this.data.button.action()

        // finally kill self
        this.destroySelf()
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Set the countdown to kill itself
    this.timeoutFunction = setTimeout(this.destroySelf, this.data.timeout ? this.data.timeout : 3000)
  }
}
</script>
